import React from "react"
import { Link } from "gatsby"

import NavbarNormal from "../Navigation/NavbarNormal"
import NavbarMobile from "../Navigation/NavbarMobile"
// import ITLLogo from "../assets/images/itl_logo.png"
// import Logo from "../../../static/icons/Inditech-Labs-Logo.png"
import fogLogo from '../../../static/icons/FOG_logo2.svg';


export default function Header() {
    return(
        <header className="header">
            {/* <img className="logo" src={ ITLLogo } /> */}
            <a href="/"><img className="logo" src={ fogLogo } alt="Future of Gaming"/></a>
            <NavbarNormal page="" />
            <NavbarMobile page="" />
        </header>
    )
}
